import ListProducts from "../components/ListProducts/Products";
import Header from "../components/Header/Header";
import ButtonBack from "../components/Header/HeaderComponents/ButtonBack/ButtonBack";

import trap from './../photo/trap.png'

export default function TrapPage(props) {
    return (
        <div className="trap-page">
            <Header photo={trap}/>
            <ListProducts products={props.products} onClick={props.onClick}/>
            <ButtonBack />
        </div>
    )
}