import { Link } from "react-router-dom";
import './Main.css'

import detrot from './../../photo/Detroit.png'
import trap from './../../photo/trap.png'
import drill from './../../photo/Drill.png'
import jersey from './../../photo/Jersey.png'

export default function Main(props) {
    return (
        <div className="fon-main">
            <Link to="detroit" onClick={() => {
                localStorage.setItem('page', JSON.stringify('/detroit'))
            }}>
                <img className="menu-button" src={detrot}></img>
            </Link>
            <Link to="trap" onClick={() => {
                localStorage.setItem('page', JSON.stringify('/trap'))
            }}>
                <img className="menu-button" src={trap}></img>
            </Link>
            <Link to="drill" onClick={() => {
                localStorage.setItem('page', JSON.stringify('/drill'))
            }}>
                <img className="menu-button" src={drill}></img>
            </Link>
            <Link to="jersey" onClick={() => {
                localStorage.setItem('page', JSON.stringify('/jersey'))
            }}>
                <img className="menu-button" src={jersey}></img>
            </Link>
        </div>

    )
}