
import Header from "../components/Header/Header";
import Main from "../components/Menu/Main";

import header from './../photo/файл.png'

export default function MainPage(props) {
    localStorage.setItem('page', JSON.stringify('/'))
    return (
        <>
            <Header photo={header}/>
            <Main />
        </>
    )
}