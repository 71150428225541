import Cart from "../components/Cart/Cart";
import ButtonBack from "../components/Header/HeaderComponents/ButtonBack/ButtonBack";

export default function CartPage() {
    return (
        <div className="cart-page">
            <Cart />
            <ButtonBack path={JSON.parse(localStorage.getItem('page'))}/>
        </div>
        
    )
}