import savage from './photo/buttons/21 Savage.png'
import babyTron from './photo/buttons/Baby Tron.png'
import big from './photo/buttons/Big 30.png'
import bossman from './photo/buttons/BossMan Dlow.png'
import estGee from './photo/buttons/Est Gee.png'
import richZay from './photo/buttons/GetRichZay.png'
import key from './photo/buttons/Key Glock.png'
import baby from './photo/buttons/Lil Baby.png'
import durk from './photo/buttons/Lil Durk.png'
import wick from './photo/buttons/Nardo Wick.png'
import rio from './photo/buttons/Rio Da Yung OG.png'
import rob from './photo/buttons/Rob 49.png'
import fatt from './photo/buttons/YTB Fatt.png'

import six from './photo/buttons/6ix9ine.png'
import face from './photo/buttons/Blueface.png'
import come from './photo/buttons/Comethazine.png'
import tape from './photo/buttons/Big Baby Tape.png'
import kizaru from './photo/buttons/Kizaru.png'
import nle from './photo/buttons/NLE Choppa.png'
import kodak from './photo/buttons/Kodak Black.png'




export default function infoPage(page) {
    if (page == 'detroit') {
        // DETROIT
        const performersAndButtonImg = [
            {
                name: '21 Savage',
                url: savage
            }, 
            {
                name: 'Baby Tron',
                url: babyTron
            }, 
            {
                name: 'Big30',
                url: big
            }, 
            {
                name: 'BossMan Dlow',
                url: bossman
            }, 
            {
                name: 'Est Gee',
                url: estGee
            }, 
            {
                name: 'GetRichZay',
                url: richZay
            }, 
            {
                name: 'Key Glock',
                url: key
            }, 
            {
                name: 'Lil Baby',
                url: baby
            }, 
            {
                name: 'Lil Durk',
                url: durk
            }, 
            {
                name: 'Nardo Wick',
                url: wick
            }, 
            {
                name: 'Rio Da Yang Og',
                url: rio
            },
            {
                name: 'Rob49',
                url: rob
            },
            {
                name: 'YTB Fatt',
                url: fatt
            },
        ]

        const performersAndLabelImg = [
            {
                name: '21 Savage',
                url: 'https://i.postimg.cc/50RR5FrF/photo-2024-04-23-22-18-49.jpg'
            }, 
            {
                name: 'Baby Tron',
                url: 'https://i.postimg.cc/x1jQr4GJ/photo-2024-04-23-01-36-22.jpg'
            }, 
            {
                name: 'Big30',
                url: 'https://i.postimg.cc/Fztv2FGN/photo-2024-04-23-00-04-51.jpg'
            }, 
            {
                name: 'BossMan Dlow',
                url: 'https://i.postimg.cc/8cfnZz8X/photo-2024-04-23-23-03-28.jpg'
            }, 
            {
                name: 'Est Gee',
                url: 'https://i.postimg.cc/020vBsS1/photo-2024-04-22-23-04-08.jpg'
            }, 
            {
                name: 'GetRichZay',
                url: 'https://i.postimg.cc/j5MhDRP4/photo-2024-04-22-22-25-54.jpg'
            }, 
            {
                name: 'Key Glock',
                url: 'https://i.postimg.cc/7YkD96fN/photo-2024-04-23-00-16-30.jpg'
            }, 
            {
                name: 'Lil Baby',
                url: 'https://i.postimg.cc/FRkmWkyT/photo-2024-04-22-23-50-03.jpg'
            }, 
            {
                name: 'Lil Durk',
                url: 'https://i.postimg.cc/V66hbFK8/photo-2024-04-23-22-51-51.jpg'
            }, 
            {
                name: 'Nardo Wick',
                url: 'https://i.postimg.cc/xd4n33zk/photo-2024-04-22-23-03-59.jpg'
            }, 
            {
                name: 'Rio Da Yang Og',
                url: 'https://i.postimg.cc/pryJ83Bb/photo-2024-04-22-22-25-53.jpg'
            },
            {
                name: 'Rob49',
                url: 'https://i.postimg.cc/v89b59HB/photo-2024-04-22-23-04-22.jpg'
            },
            {
                name: 'YTB Fatt',
                url: 'https://i.postimg.cc/hv9ngF3x/photo-2024-04-23-00-29-42.jpg'
            },
        ]

        const lstDelStr = [
            '21 Savage ', 'Baby Tron ', 'Big30 ', 'BossMan Dlow ', 'Est Gee ', 'GetRichZay ', 'Key Glock ', 'Lil Baby ',
            'Lil Durk ', 'Nardo Wick ', 'Rio Da Yang Og ', 'Rob49 ', 'YTB Fatt '
        ]


        return [performersAndButtonImg, performersAndLabelImg, lstDelStr]

    } else if (page == 'trap') {
        // DRILL
        const performersAndButtonImg = [
            {
                name: '6ix9ine',
                url: six
            }, 
            {
                name: 'Blue face baby',
                url: face
            }, 
            {
                name: 'Comethazin',
                url: come
            }, 
            {
                name: 'Big Baby Tape',
                url: tape
            }, 
            {
                name: 'Kizaru',
                url: kizaru
            }, 
            {
                name: 'Nle Choppa',
                url: nle
            }, 
            {
                name: 'Kodak black',
                url: kodak
            },
        ]

        const performersAndLabelImg = [
            {
                name: '6ix9ine',
                url: 'https://i.postimg.cc/6QPjTPRz/photo-2024-06-27-16-41-35.jpg'
            }, 
            {
                name: 'Blue face baby',
                url: 'https://i.postimg.cc/Rh7XsVYb/photo-2024-06-26-00-48-28.jpg'
            }, 
            {
                name: 'Comethazin',
                url: 'https://i.postimg.cc/nh0wfrhy/photo-2024-06-27-16-41-34.jpg'
            }, 
            {
                name: 'Big Baby Tape',
                url: 'https://i.postimg.cc/Z5TDQp4P/photo-2024-06-26-01-38-16.jpg'
            }, 
            {
                name: 'Kizaru',
                url: 'https://i.postimg.cc/5tyK4JH6/photo-2024-06-26-17-45-38.jpg'
            }, 
            {
                name: 'Nle Choppa',
                url: 'https://i.postimg.cc/4ykWQhW9/photo-2024-06-26-00-48-29.jpg'
            }, 
            {
                name: 'Kodak black',
                url: 'https://i.postimg.cc/DZbCGcPh/photo-2024-06-27-17-28-05.jpg'
            },
        ]

        const lstDelStr = [
            '6ix9ine ', 'Blue face baby ', 'Comethazin ', 'Big Baby Tape ', 'Kizaru ', 'Nle Choppa ', 'Kodak black '
        ]
        
        return [performersAndButtonImg, performersAndLabelImg, lstDelStr]
    }
}

