import { Link } from "react-router-dom"
import { HOST_CLIENT } from "../../../../env"
import './ButtonBack.css'

import backProducts from './../../../../photo/back to the products.png'
import backMenu from './../../../../photo/back to the menu.png'

export default function ButtonBack({path='/'}) {
    let data
    if ((JSON.parse(localStorage.getItem('page')) == '/') || (window.location.href == 'https://lilveezee.store/trap') ||  (window.location.href == 'https://lilveezee.store/detroit') || (window.location.href == 'https://lilveezee.store/jersey') ||  (window.location.href == 'https://lilveezee.store/drill')) {
        data = backMenu
    }
    else data = backProducts
    return (
        <Link to={path}>
            <img src={data} className="button-back" id="back"></img>
        </Link >
    )
}