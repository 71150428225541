import { Component } from 'react';
import { SlBasket } from "react-icons/sl";
import './header.css'
import '../ListProducts/Products.css'
import { Link } from 'react-router-dom';

import about from './../../photo/about us.png'
import contacts from './../../photo/contacts.png'
import prices from './../../photo/prices.png'
import cart from './../../photo/cart.png'

import { HOST_CLIENT, HOST_SERVER_API } from "../../env"

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            buyCounter: undefined,
            isGetChatId: false
        }
        this.sumCounterProduct = this.sumCounterProduct.bind(this)

        window.sumCounterProduct = this.sumCounterProduct
        this.sumCounterProduct()
    }

    render() {
        return (
            <div className='header' id='header' style={{
                background: `url(${this.props.photo})`, 
                backgroundRepeat: 'no-repeat', 
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                fontFamily: 'Arial Narrow, sans-serif',
                marginBottom: 'calc(var(--index) * 7)',
                height: 'calc(var(--index) * 22)'
            }}>

                <div className='header-menu' id='menu'>
                    <Link to="/about-as" className={'custom-link ' + (this.props.selectedPage === 1 ? 'selected-block' : '')}>
                        <img src={about}
                        className={'header-menu-button ' + (this.props.selectedPage === 1 ? 'selected-button' : '')}></img>
                    </Link>

                    <Link to="/contacts" className={'custom-link ' + (this.props.selectedPage === 3 ? 'selected-block' : '')}>
                        <img src={contacts}
                        className={'header-menu-button ' + (this.props.selectedPage === 3 ? 'selected-button' : '')}></img>
                    </Link>

                    <Link to="/reviews" className={'custom-link ' + (this.props.selectedPage === 2 ? 'selected-block' : '')}>
                        <img src={prices}
                        className={'header-menu-button ' + (this.props.selectedPage === 2 ? 'selected-button' : '')}></img>
                    </Link>

                    
                    
                    <Link to="/cart">
                        <span className='circle-counter' style={{display: this.state.buyCounter ? 'flex' : 'none'}}
                        >{this.state.buyCounter}</span>
                        <img src={cart} className='cart-button'></img>
                        
                    </Link>
                </div>
            </div>
            
        )
    }

    componentDidMount() {
        document.addEventListener('scroll', this.headerFixedRelative, { passive: true })
        let menu = document.getElementById('menu')
        if (window.location.href != (HOST_CLIENT) + '/') {
            menu.style.paddingBottom = '3vh'
        } 
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.headerFixedRelative)
        localStorage.setItem('scroll-products', Math.floor(window.pageYOffset))
    }

    // функции для обработки скролла страницы
    headerFixedRelative() {
        let menu = document.getElementById('menu')
        if (window.location.href == (HOST_CLIENT) + '/') {
            menu.style.paddingBottom = 0
        } 
        // если меню выходит за верхнюю границу то фиксируем его в верхней части видимой области
        else if (window.pageYOffset > 270 ) {
            menu.style.background = 'linear-gradient(to bottom, black, transparent 600%)'
            menu.style.position = 'fixed'
            menu.style.top = 0
            menu.style.paddingBottom = 0
        } else {
            menu.style.background = 'linear-gradient(to bottom, transparent, black 99%)'
            menu.style.position = 'absolute'
            menu.style.top = 'calc(var(--index) * 22)'
            menu.style.paddingBottom = '3vh'
        }
        // если человек на главной странице, записываем его информацию о скролле страницы каждую секунду
        if (window.location.href == (HOST_CLIENT + '/detroit') || window.location.href == (HOST_CLIENT + '/trap')) {
            console.log('sdf')
            setTimeout(() => localStorage.setItem('scroll-products', Math.floor(window.pageYOffset)), 100)
        }
        
    }


    // функции пересчета количества товаров в корзине
    sumCounterProduct() {
        fetch(HOST_SERVER_API + 'length', {
            method: 'GET'
        })
        .then(response => response.json())
        .then(data => this.setState({buyCounter: data.length}))
        .catch(error => console.log(error))

    }
    
}

export default Header;
