import Header from "../Header";
import ButtonBack from "./ButtonBack/ButtonBack";
import './Reviews.css';

import header from './../../../photo/файл.png'
import detroit from './../../../photo/Detroit.png'
import trap from './../../../photo/trap.png'

export default function Reviews() {    
    return (
        <div className="reviews-page">
            <Header selectedPage={2} photo={getPhoto()}/>
            <div className="prices-block">
                <h1 className="prices-header">Prices</h1>
                <p className="title">Leasing <span className="separator">--------------------</span> <span className="price">30$</span></p>
                <p className="title">Exclusive <span className="separator">------------------</span> <span className="price">90$</span></p>
            </div>

            <div className="stocks-block">
                <h1 className="stocks-header">Stocks</h1>
                <p className="title">SOON</p>
            </div>
            <ButtonBack path={JSON.parse(localStorage.getItem('page'))}/>
        </div>
    )
}

function getPhoto() {
    let page = JSON.parse(localStorage.getItem('page'))
    if (page == '/') {
        return header
    } else if (page == '/detroit') {
        return detroit
    } else if (page == '/trap') {
        return trap
    }
}