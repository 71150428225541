
import ButtonBack from "../components/Header/HeaderComponents/ButtonBack/ButtonBack";

import soon from './../photo/soon.png'

export default function ComingSoon(props) {
    return (
        <>
            <img src={soon}
            style={{width: '100%', height: '100%'}}></img>
            <ButtonBack />
        </>
    )
}